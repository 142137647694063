import moment from "moment";

export const dateConvertDdMmmYyyy = (inputFormat) => moment(inputFormat).format("DD MMM YYYY");

export const dateConvertToDay = (inputFormat) => moment(inputFormat).format("dddd");

export const dateConvertDdMmmYyyyHhMm = (inputFormat) => moment(inputFormat).format("DD MMMM yyyy, HH:mm");

export const dateConvertDdMmmYyyyHhMmss = (inputFormat) => moment(inputFormat).format("DD MMMM yyyy, HH:mm:ss");

export const MaxPayment = (
	datetime,
	isWithDay = false,
	amountOfTime = 2,
	unitOfTime = "hours",
) => {

	const day = isWithDay ? `${dateConvertToDay(moment(datetime).add(amountOfTime, unitOfTime))}, ` : "";
	const dateAndTime = dateConvertDdMmmYyyyHhMm(moment(datetime).add(amountOfTime, unitOfTime));
	return day + dateAndTime;

};

export const formatPromoDate = (startDate, endDate) => {

	let formattedStartDate = dateConvertDdMmmYyyy(startDate);
	const formattedEndDate = dateConvertDdMmmYyyy(endDate);

	// If there is start date and end date
	if (startDate && endDate) {

		const hasPromoStarted = moment().diff(moment(startDate)) > 0;

		// If has already started (user can already use the promo) it will show end date. ex: Ends X
		if (hasPromoStarted) {

			return `Ends ${formattedEndDate}`;

		}
		// If has NOT started yet, it will show start date - end date. ex: X - X
		// if in the same year : 22 Jun - 22 Aug 2023
		// if different year : 19 Dec 2023 - 19 Jan 2024
		const currentYear = moment().year();
		const endYear = moment(endDate).year();

		if (currentYear === endYear) {

			formattedStartDate = moment(formattedStartDate).format("DD MMM");

		}

		return `${formattedStartDate} - ${formattedEndDate}`;

	} if (startDate) {

		// If there is NO end date will only show start date. ex: Starts X
		return `Starts ${formattedStartDate}`;

	}

	return "";

};
