/* eslint-disable import/prefer-default-export */

export const setDataLayer = (dataObj, urlTarget) => {

	if (urlTarget === "GTM") {

		const dataLayer = window.dataLayer || [];

		if (typeof dataObj === "object" && dataObj !== null) {

			dataLayer.push(dataObj);

		}

	}

};
