/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from "react";
import moment from "moment";
import { dateConvertDdMmmYyyyHhMm, dateConvertToDay } from "Helpers/dateWithMoment";

export const useDebounceValue = (value, delay) => {

	const [debouncedValue, setDebouncedValue] = useState(
		value,
	);

	useEffect(() => {

		const handler = setTimeout(() => {

			setDebouncedValue(value);

		}, delay);

		return () => {

			clearTimeout(handler);

		};

	}, [value]);

	return debouncedValue;

};

export const useGetAppliedHook = ({
	addCondition = true,
	appliedCouponCode,
	isLoadCartTotal,
	setMultiCoupons,
	multiCoupon,
	setPromo,
	promo,
}) => useEffect(() => {

	/* Promotion Feature */
	// biar auto centang di list promo nya setelah load applied
	const multipleCoupon = [...multiCoupon];
	if (addCondition
		&& Array.isArray(appliedCouponCode) && promo?.length > 0) {

		const newData = promo?.map((item) => {

			if (item?.coupon.length > 0 && appliedCouponCode?.includes(item?.coupon[0].code)) {

				// eslint-disable-next-line no-param-reassign
				item.selected = true;
				multipleCoupon.push(item.id);

			} else {

				// eslint-disable-next-line no-param-reassign
				item.selected = false;
				// eslint-disable-next-line no-param-reassign
				item.isApply = false;

			}
			return item;

		});

		setMultiCoupons(multipleCoupon);
		setPromo(newData);

	}

}, [isLoadCartTotal]);

export const useMaxPayment = (
	datetime,
	isWithDay = false,
	amountOfTime = 2,
	unitOfTime = "hours", // "days", "hours", "minutes", "seconds", "milliseconds"
) => {

	const [formattedDatetime, setFormattedDatetime] = useState("");

	useEffect(() => {

		const day = dateConvertToDay(moment(datetime).add(amountOfTime, unitOfTime));
		const dateAndTime = dateConvertDdMmmYyyyHhMm(moment(datetime).add(amountOfTime, unitOfTime));

		setFormattedDatetime(`${isWithDay ? `${day}, ` : ""}${dateAndTime}`);

	}, [datetime]);

	return formattedDatetime;

};

export const useHideLeftScroll = (
	itemList = ".child-list-products-mobile",
	ObjectToLeft = ".child-list-products-mobile-inner-container",
	objectHide = ".left-banner-wrapper",
) => {

	// const [resDistanceToLeft, setResDistanceToLeft] = useState(0);
	// eslint-disable-next-line consistent-return
	useEffect(() => {

		const header = document?.querySelector(objectHide) || null;
		const scrollList = document?.querySelector(itemList) || null;
		const distanceObjectToLeft = document?.querySelector(ObjectToLeft) || null;

		const fadeOutOnScroll = () => {

			if (!header) {

				console.log("element not found");
				return;

			}

			const calcDistanceToLeft = window.scrollX
				+ distanceObjectToLeft.getBoundingClientRect().left;
			// setResDistanceToLeft(calcDistanceToLeft);
			const elementWidth = header.offsetWidth;
			const { scrollLeft } = scrollList;

			let opacity = 1;

			if (scrollLeft > calcDistanceToLeft) {

				opacity = 1 - ((scrollLeft - calcDistanceToLeft) / elementWidth);

			}

			// eslint-disable-next-line no-param-reassign
			header.style.opacity = opacity;

		};
		// eslint-disable-next-line no-unused-expressions
		scrollList.addEventListener("scroll", fadeOutOnScroll);

		return () => scrollList.removeEventListener("scroll", fadeOutOnScroll);

	});

	// return [resDistanceToLeft];

};
